"use client"
import { useInfos, useLocals } from "@/client/store/store"
import { Button } from "@/client/components/atoms/ui/button"
import { useRouter, useSearchParams } from "next/navigation"
import { ROUTES } from "@/client/constants/constants"
import getFullUrl from "@/client/services/auth/get-url"

export default function NextStepNavigation() {
	const router = useRouter()
	const searchParams = useSearchParams()
	const decodedRedirectPath = searchParams.get("redirect_path")

	const { currentStep } = useLocals(state => ({
		currentStep: state.currentStep,
	}))

	const updateDashboardRoute = (code: string): string =>
		`/${code.toLowerCase()}/${ROUTES.AI_ASSIST}`

	const info = useInfos(state => state.infos)
	function handleClick() {
		if (currentStep === 2) {
			if (decodedRedirectPath) {
				router.push(getFullUrl(`${decodedRedirectPath}`))
			}
		}
		if (currentStep === 3) {
			const dashboard = updateDashboardRoute(info.preferred_country)
			router.refresh()
			router.push(dashboard)
		}
	}

	return (
		<div className="flex items-center justify-between">
			<Button
				type="submit"
				variant="onboarding"
				className="flex gap-2 !text-xs"
				onClick={handleClick}>
				<span>
					{(() => {
						if (currentStep === 2) {
							return "Finish"
						} else if (currentStep === 3) {
							return "Go to Dashboard"
						} else {
							return "Next"
						}
					})()}
				</span>
				{currentStep === 2 ? (
					<span>
						<svg
							width="12"
							height="10"
							viewBox="0 0 12 10"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M1 5.23811L4.33333 8.57145L11 1.42859"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
				) : (
					<span>
						<svg
							width="12"
							height="11"
							viewBox="0 0 12 11"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M7.16151 10.4131L11.9642 5.58543L7.16151 0.757775C7.11813 0.700816 7.06303 0.653841 6.99992 0.620031C6.93681 0.58622 6.86718 0.566364 6.79573 0.561805C6.72428 0.557247 6.65269 0.568093 6.58579 0.593609C6.5189 0.619125 6.45827 0.658715 6.40801 0.7097C6.35775 0.760684 6.31903 0.821873 6.29447 0.889123C6.26991 0.956373 6.26009 1.02811 6.26567 1.09949C6.27125 1.17087 6.2921 1.24021 6.32681 1.30283C6.36152 1.36545 6.40927 1.41988 6.46684 1.46243L10.0601 5.08567L0.499745 5.08567C0.367202 5.08567 0.240087 5.13832 0.146364 5.23205C0.0526419 5.32577 -1.14441e-05 5.45288 -1.14441e-05 5.58543C-1.14441e-05 5.71797 0.0526419 5.84509 0.146364 5.93881C0.240087 6.03253 0.367202 6.08519 0.499745 6.08519L10.0601 6.08519L6.46684 9.70842C6.3734 9.80253 6.32117 9.9299 6.32164 10.0625C6.32211 10.1951 6.37524 10.3221 6.46934 10.4156C6.56345 10.509 6.69082 10.5613 6.82344 10.5608C6.95606 10.5603 7.08306 10.5072 7.1765 10.4131H7.16151Z"
								fill="white"
							/>
						</svg>
					</span>
				)}
			</Button>
		</div>
	)
}
