"use client"

import { InfosState } from "@/client/lib/types/onboarding-types"
import { create } from "zustand"
import { z } from "zod"
import { SEARCH_YEARS, SEARCH_QUARTERS, SENTIMENTS } from "@/client/constants"
import { stringToArray } from "@/client/lib/helpers"

type FieldType = "preferred_country" | "first_name" | "last_name" | "investor_type"

interface InfosStore {
	infos: InfosState
	completed: boolean
	setCompleted: (completed: boolean) => void
	setInfos: (field: FieldType, value: string) => void
}

export const useInfos = create<InfosStore>(set => ({
	infos: {
		preferred_country: "",
		first_name: "",
		last_name: "",
		investor_type: "",
	},
	completed: false,
	setCompleted: completed => set(state => ({ ...state, completed })),
	setInfos: (field, value) =>
		set(state => ({
			...state,
			completed: false,
			infos: {
				...state.infos,
				[field]: value,
			},
		})),
}))

interface LocalsStore {
	currentStep: number
	isPending: boolean
	setCurrentStep: (currentStep: number) => void
	setIsPending: (isPending: boolean) => void
}

export const useLocals = create<LocalsStore>(set => ({
	currentStep: 0,
	isPending: false,
	setCurrentStep: currentStep => set(state => ({ ...state, currentStep })),
	setIsPending: isPending => set(state => ({ ...state, isPending })),
}))

const AnnouncementRawSearchSchema = z
	.object({
		company_id: z.string().transform(stringToArray).optional(),
		from_date: z.coerce.date().optional(),
		to_date: z.coerce.date().optional(),
		page: z.coerce.number().catch(1).optional(),
		"filing_8k_type_id": z.string().transform(stringToArray).optional(),
		announcement_type_id: z.string().transform(stringToArray).optional(),
		sentiment: z.string().transform(stringToArray).optional(),
		sector: z.string().transform(stringToArray).optional(),
		industry: z.string().transform(stringToArray).optional(),
		year: z.string().transform(stringToArray).optional(),
		quarter: z.string().transform(stringToArray).optional(),
		watchlist: z.string().transform(stringToArray).optional(),
		marketcap_category: z.string().transform(stringToArray).optional(),
	})

const refineExtendedAnnouncementSharedSchema = (schema: typeof AnnouncementRawSearchSchema) => schema.refine(data => {
	if (!(data.from_date && data.to_date && data.from_date <= data.to_date)) {
		delete data.from_date
		delete data.to_date
	}
	if (data.sentiment?.length) {
		data.sentiment = data.sentiment.filter(val =>
			SENTIMENTS.options.map(item => item.id).includes(val)
		)
	}
	if (data.year?.length) {
		data.year = data.year.filter(val => SEARCH_YEARS.includes(val))
	}
	if (data.quarter?.length) {
		data.quarter = data.quarter.filter(val => SEARCH_QUARTERS.includes(val))
	}
	return data
})

export const AnnouncementSearchSchema = refineExtendedAnnouncementSharedSchema(AnnouncementRawSearchSchema)

export const AnnouncementTextSearchSchema = refineExtendedAnnouncementSharedSchema(AnnouncementRawSearchSchema.extend({
	query: z.string()
}))
