"use client"

import * as React from "react"
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import { Circle } from "lucide-react"

import { cn } from "@/client/lib/utils"

const RadioGroup = React.forwardRef<
	React.ElementRef<typeof RadioGroupPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
	// eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
	<RadioGroupPrimitive.Root className={cn("grid gap-2", className)} {...props} ref={ref} />
))

RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
	React.ElementRef<typeof RadioGroupPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
	// eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
	<RadioGroupPrimitive.Item
		ref={ref}
		className={cn(
			"h-3 w-3 rounded-full outline-none",
			props.checked
				? "ring-2 ring-primary ring-offset-1 ring-offset-primary"
				: "ring-1 ring-zinc-300 ring-offset-2 ring-offset-white",
			className
		)}
		{...props}>
		<RadioGroupPrimitive.Indicator className="flex items-center justify-center border-none">
			<Circle className="h-2 w-2 fill-white stroke-none text-current" />
		</RadioGroupPrimitive.Indicator>
	</RadioGroupPrimitive.Item>
))
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem }
