import React from "react"
import { FormControl, FormItem, FormLabel } from "@/client/components/atoms/ui/form"
import { RadioGroupItem } from "@/client/components/atoms/ui/radio-group"

type RadioButtonProps = {
	checked: boolean
	children: React.ReactNode
	value: string
	onSelect: () => void
}

const RadioButton = ({ checked, onSelect, value, children }: RadioButtonProps) => (
	<FormItem
		className={`flex h-[50px] cursor-pointer items-center gap-4 rounded-md border border-solid bg-white pl-3 hover:border-neutral-300 max-md:max-w-full ${
			checked ? "border-neutral-300" : ""
		}`}>
		<FormControl>
			<RadioGroupItem checked={checked} value={value} className="h-2 w-2" />
		</FormControl>
		<FormLabel
			className="flex h-full w-full cursor-pointer flex-col items-stretch font-normal"
			onClick={onSelect}>
			<div className="flex grow basis-[0%] flex-col items-stretch justify-center self-stretch">
				{children}
			</div>
		</FormLabel>
	</FormItem>
)

export default RadioButton
